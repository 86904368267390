import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { GarrampaApiServicesModule } from '@garrampa/api-services';
import { environment } from '../environments/environment';
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MatLuxonDateModule,
} from '@angular/material-luxon-adapter';
import {
  NoPreloading,
  provideRouter,
  TitleStrategy,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';
import { APP_ROUTES } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { CustomTitleStrategy } from './core/title-strategy/custom-title-strategy';
import { provideAnimations } from '@angular/platform-browser/animations';
import { storageFactory } from './app.module';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      OAuthModule.forRoot(),
      GarrampaApiServicesModule.forRoot({
        apiUrl: environment.apiUrl,
      }),
      MatLuxonDateModule,
    ),
    provideRouter(
      APP_ROUTES,
      withPreloading(NoPreloading),
      withComponentInputBinding(),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
};
