import { MenuModel } from '../models/menu-section.model';

export const availableSections: MenuModel = [
  {
    icon: 'campaign',
    name: 'Campañas',
    uri: 'campaigns',
    permissionName: 'cardz',
  },
  {
    icon: 'credit_card',
    name: 'Pedidos',
    uri: 'cardz',
    permissionName: 'cardz',
  },
  {
    icon: 'inventory',
    name: 'Catálogo CardZ',
    uri: 'cardz-catalogue',
    permissionName: 'cardz',
  },
  {
    icon: 'app_registration',
    name: 'Elige tú',
    uri: 'custom-order-wizard',
    permissionName: 'cardz',
  },
  {
    icon: 'analytics',
    name: 'Reporting',
    uri: 'reporting',
    permissionName: 'cardz',
  },
  {
    icon: 'shopping_bag',
    name: 'Canjes',
    uri: 'redemptions',
    permissionName: 'cardz',
  },
  {
    icon: 'local_shipping',
    name: 'Catálogo productos',
    uri: 'products-catalogue',
    permissionName: 'corporatez',
  },
  {
    icon: 'trolley',
    name: 'Productos',
    uri: 'products',
    permissionName: 'cardzAdmin',
  },
  {
    icon: 'category',
    name: 'Categorías',
    uri: 'categories',
    permissionName: 'cardzAdmin',
  },
];
