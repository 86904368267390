import { Component, isDevMode, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { ResizeWindowService } from '../../shared/services/resize-window.service';
import { filter, map, startWith } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ThemeService } from '../../shared/services/theme.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    SidebarMenuComponent,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    RouterOutlet,
  ],
})
export class BaseLayoutComponent implements OnInit {
  menuOpened = true;
  deviceType: 'mobile' | 'tablet' | 'desktop' = 'desktop';
  currentRouteTitle = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resizeWindowService: ResizeWindowService,
    public themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    // Get route title from child route
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith({}),
        map(() => this.route.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.currentRouteTitle = route.title || '';
      });

    this.resizeWindowService.getDeviceType().subscribe(deviceType => {
      this.deviceType = deviceType;
      this.onWindowResize();
    });
  }

  onWindowResize() {
    // Open menu when switching to desktop mode and close it when switching to mobile mode
    this.menuOpened =
      this.deviceType === 'desktop' || this.deviceType === 'tablet';
  }

  clickMenuButton(): void {
    this.menuOpened = !this.menuOpened;
  }

  protected readonly isDevMode = isDevMode;
}
