<img class="logo" src="assets/logo_branz_b.png" />
<mat-nav-list [disableRipple]="true">
  <a
    mat-list-item
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="'/dashboard'">
    <mat-icon matListItemIcon>home</mat-icon>
    <span>Inicio</span>
  </a>
  @for (item of userMenu; track item) {
    <a mat-list-item routerLinkActive="active-route" [routerLink]="item.uri">
      <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
      <span>{{ item.name }}</span>
    </a>
  }
</mat-nav-list>

<div class="user-logo">
  @if (themeLogoUrl) {
    <img [src]="themeLogoUrl" />
  }
</div>

<div class="user-info">
  <mat-list>
    <mat-list-item>
      <span matListItemTitle>{{ userFullName }}</span>
      <div matListItemLine>{{ username }}</div>
    </mat-list-item>
  </mat-list>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon matListItemMeta>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/apps-launcher">
      <mat-icon>rocket_launch</mat-icon>
      <span>Otras aplicaciones</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Cerrar sesión</span>
    </button>
  </mat-menu>
</div>
