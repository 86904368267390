import { Component, Input, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../../auth/auth.service';
import { MenuModel } from '../../../shared/models/menu-section.model';
import { PermissionService } from '../../permissions/permission.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
  ],
})
export class SidebarMenuComponent implements OnInit {
  userFullName = '';
  username = '';
  userMenu: MenuModel = [];

  @Input()
  themeLogoUrl?: string;

  constructor(
    private oauthService: OAuthService,
    private authService: AuthService,
    private permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.loadUserDetails();
    this.userMenu = this.permissionService.getUserAllowedSections();
  }

  logout(): void {
    this.oauthService.logOut();
  }

  loadUserDetails(): void {
    this.userFullName = this.authService.getFullName();
    this.username = this.authService.getEmail();
  }
}
