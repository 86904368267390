@if (isDevMode()) {
  <div class="dev-bar"><mat-icon>code</mat-icon>Entorno de pruebas</div>
}

<mat-drawer-container autosize>
  <mat-drawer
    [(opened)]="menuOpened"
    [mode]="deviceType === 'mobile' ? 'push' : 'side'">
    <app-sidebar-menu
      [themeLogoUrl]="themeService.theme?.logoUrl"></app-sidebar-menu>
  </mat-drawer>

  <mat-drawer-content>
    @if (deviceType === 'mobile') {
      <mat-toolbar>
        <div class="left">
          <button mat-icon-button (click)="clickMenuButton()">
            <mat-icon>menu</mat-icon>
          </button>
          <img
            alt="Logotipo"
            class="logo-toolbar"
            src="/assets/logo_branz.png" />
        </div>
        <div class="right">
          <button mat-icon-button><mat-icon>person</mat-icon></button>
        </div>
      </mat-toolbar>
    }

    <!--    <app-toolbar-menu (clickMenuButton)="clickMenuButton()"></app-toolbar-menu>-->
    <!--    <div class="section-title">{{ currentRouteTitle }}</div>-->
    <main>
      <!--      <div class="back-to-shop-container">-->
      <!--        <mat-chip>-->
      <!--          <mat-icon matChipAvatar>arrow_back</mat-icon>-->
      <!--          Volver a la tienda-->
      <!--        </mat-chip>-->
      <!--      </div>-->
      <h1 [style]="'color: ' + themeService.theme?.cssColor">
        {{ currentRouteTitle }}
      </h1>
      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
