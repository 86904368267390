import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  Observable,
  startWith,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeWindowService {
  private deviceType$ = new BehaviorSubject<'mobile' | 'tablet' | 'desktop'>(
    'desktop',
  );

  private currentWidth$ = new BehaviorSubject<number>(0);

  // TODO: Get from global variables
  private maxMobileWidth = 576;
  private maxTabletWidth = 768;

  constructor() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(100), startWith({}))
      .subscribe(() => {
        const width = window.innerWidth;
        this.currentWidth$.next(width);

        if (width < this.maxMobileWidth) {
          this.deviceType$.next('mobile');
        } else if (width < this.maxTabletWidth) {
          this.deviceType$.next('tablet');
        } else {
          this.deviceType$.next('desktop');
        }
      });
  }

  getDeviceType(): Observable<'mobile' | 'tablet' | 'desktop'> {
    return this.deviceType$.pipe(distinctUntilChanged());
  }
}
