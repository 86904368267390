import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.authConfig.issuer,
  redirectUri: window.location.origin + '/login',
  logoutUrl: environment.authConfig.issuer + '/Account/Logout',
  clientId: environment.authConfig.clientId,
  responseType: 'code',
  scope: 'openid profile restapi.manage role offline_access',
  showDebugInformation: environment.authConfig.debug,
  // timeoutFactor: 0.01,
  checkOrigin: true,
  userinfoEndpoint: environment.authConfig.issuer + '/connect/userinfo',

  // Refresh token automatically when it expires
  // useSilentRefresh: true,
  // silentRefreshRedirectUri: window.location.href,

  // Activate Session Checks:
  // sessionChecksEnabled: true,

  tokenEndpoint: environment.authConfig.issuer + '/connect/token',
};
