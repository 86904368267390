import { Routes } from '@angular/router';
import { BaseLayoutComponent } from './core/layout/base-layout.component';
import { authGuard } from './core/auth/auth.guard';
import { permissionGuard } from './core/permissions/permission.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.routes').then(m => m.routes),
  },
  {
    path: 'apps-launcher',
    loadChildren: () =>
      import('./pages/apps-launcher/apps-launcher.routes').then(m => m.routes),
    canMatch: [authGuard],
    canActivate: [authGuard],
  },
  {
    path: 'dashboard',
    component: BaseLayoutComponent,
    loadChildren: () =>
      import('./pages/dashboard/dashboard.routes').then(m => m.routes),
    canMatch: [authGuard],
    canActivate: [authGuard],
    canActivateChild: [permissionGuard],
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
