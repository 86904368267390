import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { MenuModel } from '../../shared/models/menu-section.model';
import { availableSections } from '../../shared/menu-available-sections/available-sections';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  userRoles: string[] = [];

  constructor(private oauthService: OAuthService) {
    this.loadUserRoles();
  }

  getUserAllowedSections(): MenuModel {
    const userRoles = this.userRoles;

    const userSections = availableSections.filter(section => {
      return userRoles.some(role => role.includes(section.permissionName));
    });
    return userSections;
  }

  loadUserRoles(): string[] {
    const userScopes = this.oauthService.getIdentityClaims()[
      'role'
    ] as string[];
    const userRoles = this.mapScopesIntoRoles(userScopes);
    this.userRoles = userRoles;

    return userRoles;
  }

  checkUserHasRole(role: string) {
    return this.userRoles.includes(role);
  }

  private mapScopesIntoRoles(scopes: string[]): string[] {
    const roles: string[] = [];
    scopes.forEach(scope => {
      switch (scope.toLowerCase()) {
        case 'cardz':
          roles.push(...['cardz.view', 'cardz.edit', 'cardz.configure']);
          break;
        case 'loyalz':
          roles.push(...['loyalz.view']);
          break;
        case 'corporatez':
          roles.push(...['corporatez.view', 'corporatez.create']);
          break;
        case 'cardz-admin':
          roles.push(...['cardzAdmin.view', 'cardzAdmin.edit']);
          break;
      }
    });

    return roles;
  }
}
